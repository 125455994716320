import React from 'react';
import { useParams } from 'react-router-dom';
import ImageGallery from 'react-image-gallery';
import './assets/profile.scss'
import Header from '../../components/Header'
import StoryImage from '../../components/StoryImage'
import Chip from '@mui/material/Chip';
import { MdWhatsapp, MdCall } from 'react-icons/md';
import { LinearProgress } from '@mui/material';

const Profile = () => {
  const getChanel = (ch) => {
    switch (ch) {
      case 'whatsapp':
        return <div className='whatsapp'><a href={`https://wa.me/${item.phone}`}><MdWhatsapp /></a></div>
      case 'direct':
        return <div className='direct'><a href={`tel:${item.phone}`}><MdCall /></a></div>
      default:
        return false
    }
  }
  let params = useParams();
  const [item, setItem] = React.useState({})
  const fetchData = () => {
    fetch(`https://usemta3gdj.execute-api.eu-central-1.amazonaws.com/prod/announcements/${params.userId}`)
      .then(response => response.json())
      .then(data => setItem(data))
      .then(data => console.log(data))
      .catch(error => console.log(`********* ${error}`))
  }

  React.useEffect(() => {
    fetchData()
  }, [])

  return (
    <div className='profile-content'>
      <Header back={true} />
      {Object.keys(item).length < 1 ? <div className='profile-loading'><LinearProgress color="primary" /></div> :
        <>
          <div className='profile-header'>
            <div className="profile-pic"><StoryImage src="https://images.unsplash.com/photo-1509721434272-b79147e0e708?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80" alt="profile image" /></div>
            <div className='profile-name'>{item.name}</div>
            <div className="profile-bio">{item.bio}</div>
          </div>
          <div className="image-slider">
            <ImageGallery
              items={item.media.map(i => ({ original: i, thumbnail:i, originalHeight:'100%', originalWidth:'100%', thumbnailWidth:'20px', thumbnailHeight:'50px'}))}
            />
          </div>
          <div className="profile-dis">{item.des}</div>
          <div className="profile-services">
            {item.services.map((i, index) => <Chip key={index} label={i.S} size="small" color="success" />)}
            {item.districts.map((i, index) => <Chip key={index} label={i.S} size="small" color="info" />)}
          </div>
          <div className="profile-forbiddens">
            Yasakları: {item.forbiddens.map((i, index) => <Chip key={index} label={i.S} size="small" color="error" />)}
          </div>
          <div className="profile-chanels">
            {item.chanels.map(i => getChanel(i.S))}
          </div>
          <div className="profile-footer">f</div>
        </>
      }
    </div>
  );
}


export default Profile;