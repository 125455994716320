// import logo from './logo.svg';
import React from 'react';
import './App.css';
import {Home, Profile} from './pages'
// import ScriptTag from 'react-script-tag';
// import {Helmet} from "react-helmet";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { pink, blue, red, green } from '@mui/material/colors';
import {Route, Routes} from 'react-router-dom';
import Story from './pages/Story';

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        // Purple and green play nicely together.
        main: pink[400],
      },
      info:{
        main: blue[200],
      },
      error:{
        main: red[500],
      },
      success:{
        main: green[600]
      }
    },
  });
  return (
    <ThemeProvider theme={theme}>
        <Routes>
            <Route path="/story" element={<Story />} />
            <Route path="/w" element={<Home/>}/>
            <Route path="/profile/:userId" element={<Profile/>}/>
        </Routes>
    </ThemeProvider>
  );
}

export default App;