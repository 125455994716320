import React from 'react';
import { HeartSwitch } from '@anatoliygatt/heart-switch';
import {MdCategory, MdMap, MdList} from 'react-icons/md';

import Chip from '@mui/material/Chip';
import { IconButton, Switch } from '@mui/material';

import Filter from "./Filter";
import MapFilter from "./MapFilter";

const ToolBar = ({toggleView, listStyle, toggleVip, vip}) => {
    const [tool,setTool] = React.useState(0)
    



    const [filters,setFilters] = React.useState([])
    const changeFilter = (f) => {
        setTool(0)
        setFilters(f)
    }
    const handleDelete = (event,value) =>{
        const updated_filters = filters.filter(item=> item!==value)
        setFilters(updated_filters)
    }

    const [district,setDistrict] = React.useState([])
    const changeDistrict = (val) => {
        setTool(0);
        setDistrict([val]);
    }
    const [checked,setChecked] = React.useState(listStyle==="irregular")
    const tg = () =>{
        setChecked(!checked)
        toggleView()
    }

    return (
        <div className="tools-container">
        
                <div className='tool-bar'>
                    <IconButton color="primary" onClick={() => { setTool(1) }} >
                        <MdCategory/>
                    </IconButton>
                    <IconButton color="info" onClick={() => { setTool(2) }} >
                        <MdMap/>
                    </IconButton>
                    {vip? <div className="place-holder">VIP</div> : <Switch color="primary" checked={checked} onChange={() => { tg() }}/>}
                    {/* <IconButton color="primary" onClick={() => { toggleView() }} >
                        <MdList/>
                    </IconButton> */}
                    <div>
                        <HeartSwitch size="sm" checked={vip} onChange={() => {toggleVip()}} /><span>VIP</span>
                    </div>
                </div>
                <div className={tool > 0 ? "tool active" : "tool"}>
                    {(() => {
                        switch (tool) {
                            case 1:
                                return <Filter onChange={changeFilter}/>
                            case 2:
                                return <MapFilter onChange={changeDistrict} />
                        }
                    })()}
                </div>
                {(filters.length>0 || district.length>0) && <div className='chip-bar'>
                    {filters.map((item,index) =><Chip key={index} label={item.val} size="small" color="primary" onDelete={e => handleDelete(e,item)} />)}
                    {district.map(item =><Chip label={item.val} size="small" color="info" onDelete={e => setDistrict([])} />)}
                    
                </div>}
                
        </div>
        
    )
}

export default ToolBar;