import React from "react";
import Stories from 'react-insta-stories'
import s1 from '../assets/img/story1.jpg'
import s2 from '../assets/img/story2.jpg'
import s3 from '../assets/img/story3.jpg'
import s4 from '../assets/img/story4.jpg'
import s5 from '../assets/img/story5.jpg'
import { useNavigate, userNavigate } from "react-router-dom";

const Header = () => {
    return (
        <div>
            <h1>Hello!</h1>
            <p>This story would have the configured header!</p>
        </div>
    )
}
const Story = () =>{
    let navigate = useNavigate();
    // const stories = ['https://plus.unsplash.com/premium_photo-1676299944682-929023fe3a3d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=687&q=80',
    // 'https://images.unsplash.com/photo-1527838832700-5059252407fa?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=698&q=80']
    const stories = [
        {
            url:s1,
            duration: 4000,
            content: (props) => (
			<div style={{ background: 'purple', padding: 20 }}>
				<h1 style={{ marginTop: '100%', marginBottom: 0 }}>🌝</h1>
                    <h1 style={{ marginTop: 5 }}>İyi şeyler geliyor.
                        Haberdar olmak için bizi Instagram'da takip edin</h1>
			</div>
		)},
        s1 , s2, s3, s4, s5]
    return (
    <Stories
        stories={stories}
        header={<Header/>}
        defaultInterval={3000}
        width={'100%'}
        height={'100%'}
        onAllStoriesEnd={e => { navigate(-1)}}
    />
    )

}

export default Story;