import React from 'react'
import '../assets/map-filter.css'
import { MapContainer, TileLayer, Marker, Popup, Polygon, Tooltip } from 'react-leaflet'
import ist_dist  from "../assets/data/data.json";
import { Button } from '@mui/material';
import userEvent from '@testing-library/user-event';



const MapFilter = ({onChange}) => {
    // const center = [51.505, -0.09]
    // const polygon = [
    //     [51.515, -0.09],
    //     [51.52, -0.1],
    //     [51.52, -0.12],
    // ]
    const purpleOptions = { color: '#88028b' }
    // const [coordinates, setCoordinates] = React.useState([]);
    const [polygon, setPolygon] = React.useState([]);
    const setFilter = () => {
        onChange({"filter":'district', 'val':polygon})
    }
    return (
        <div className='map-filter'>
                {ist_dist.length &&
                (<MapContainer

                    bounds={ist_dist[19].feature}
                    zoom={7}
                    scrollWheelZoom={false}>
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    {ist_dist.map(item =>
                        <Polygon
                            value={item.name}
                            pathOptions={purpleOptions}
                            positions={item.feature}
                            eventHandlers={{
                                click: (e) => {
                                    setPolygon(e.target.options.value)
                                },
                            }}>
                            <Tooltip>{item.name}</Tooltip>
                        </Polygon>
                    )}

                </MapContainer>)}
            <Button color="primary" variant="contained" size="small" onClick={setFilter}>{polygon.length>0? polygon : "Filter"}</Button>
        </div>
    )
}

export default MapFilter;