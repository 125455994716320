import React from 'react';
import StatusItem from './StatusItem';
import '../assets/status-bar.scss';
import {Link} from 'react-router-dom';

const StatusBar = ()=> {
    const [statuses, setStatus] = React.useState([])
    const fetchData = () => {
        fetch("https://6496d06a83d4c69925a3215e.mockapi.io/status-items")
        .then(response => response.json())
        .then(data => setStatus(data))
        .catch(error => console.log(`********* ${error}`))
    }

    React.useEffect(()=>{
        fetchData()
    }, [])

    const render = () => {
        return statuses.length > 0
        ?statuses.map((item, index) =>(<Link to="/story"><StatusItem key={index} item={item} /></Link>))
        :false
    }
return(
    <div className="status-bar">
        {render()}
        
    </div>
    )
}

export default StatusBar;