import React from "react";
import StoryImage from "../../../components/StoryImage";

const StatusItem = ({item}) => {
return (
    <div className="status-item">
    
        <StoryImage alt={item.name} src={item.url}/>
        <div className="item-name">{item.name}</div>
    </div>
)
}

export default StatusItem