import React from "react";
import '../assets/css/story-image.scss';

const StoryImage = ({src,alt}) => {
    return(
        <div className="circle-wrapper">
            <div className="success circle"></div>
            <div className="icon">
                <img src={src} alt={alt} />
            </div>
        </div>
    )
}

export default StoryImage;