// import logo from '../../logo.svg'
// import chatra from'../../assets/js/chatra.js'
import { StatusBar, ToolBar, PublicItems } from './components';
import './assets/home.css'
import Header from '../../components/Header'
import './assets/tool.scss'
import insta from './assets/img/instagram.svg'
import React from 'react';
import { useSearchParams } from 'react-router-dom';


const Home = () => {
    const [searchParams] = useSearchParams();
    const layout = searchParams.get('lay');
    const [listType, setListType] = React.useState(['irregular', 'regular'].includes(layout)? layout : 'regular')
    const toggleViewFunction = () => {
        if (listType === 'regular') setListType('irregular')
        else setListType('regular')
    }
    const [vipView, setVipView] = React.useState(layout==="vip")
    const toggleVipViewFunction = () => {
        setVipView(!vipView)
    }
    
return(
    <main>
        <Header/> 
        <StatusBar />
        <ToolBar toggleView={toggleViewFunction} listStyle={listType}  toggleVip={toggleVipViewFunction} vip={vipView}/>
        <PublicItems listStyle={listType} vipView={vipView}/>
        <div className='insta'><a href="https://www.instagram.com/keyif.life/"><img src={insta}/></a></div>
    </main>
)
}

export default Home;