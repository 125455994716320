import '../assets/public-items.scss'
import React from 'react';
import {Link} from 'react-router-dom';
import badge from '../assets/img/vip-badge.png'



import vip1 from '../assets/img/vip1.jpg'
import vip2 from '../assets/img/vip2.jpg'
import vip3 from '../assets/img/vip3.jpg'
import vip4 from '../assets/img/vip4.jpg'
import vip5 from '../assets/img/vip5.jpg'
import ilan from '../assets/img/ilan.jpg'
import ir_ilan1 from '../assets/img/ir_ilan1.jpg'
import ir_ilan2 from '../assets/img/ir_ilan2.jpg'

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle'; 

const PublicItems = ({listStyle, vipView}) => {

    const [ dialog, setDialogOpen] = React.useState(false);
    const [ message, setMessage] = React.useState("message");

    const openAnnouncementDialog = () => {
        setMessage("Bu sitede ilan yapmak için sayfanın en altında bulunan Instagram sayfasına girin ve sayfanin yöneticisine mesaj gönderin.");
        setDialogOpen(true);
    };

    const openVisitorDialog = () => {
        setMessage("ilanlarin bilgileri tamamlanıyor. ilanlarin son durumlarından haberdar olmak için Instagram sayfamızı takip edin.");
        setDialogOpen(true)
    }
  
    const closeDialog = () => {
      setDialogOpen(false);
    };


    const [banners, setBanners] = React.useState([])
    const fetchData = () => {
        fetch("https://usemta3gdj.execute-api.eu-central-1.amazonaws.com/prod/announcements")
        .then(response => response.json())
        .then(data => {setBanners(data)})
        .catch(error => console.log(`********* ${error}`))
    }

    React.useEffect(()=>{
        fetchData()
    }, [])
    const re_items = []
    for (var i = 0; i < 180; i++) re_items.push(i)
    const vip_items = []
    for (var i = 0; i < 8; i++) vip_items.push(i)

    return (
        <div className='public-items'>

            <Dialog
                open={dialog}
                onClose={closeDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Keyif web sitesinin değerli mısafırı.
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    {message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialog} autoFocus>
                        Tamam
                    </Button>
                </DialogActions>
            </Dialog>












            {vipView ?
                <div className="vip">
                    <div className="ad-item"><img className='pic' src={vip1}/><img className='badge' src={badge}/></div>
                    <div className="ad-item"><img className='pic' src={vip2}/><img className='badge' src={badge}/></div>
                    <div className="ad-item"><img className='pic' src={vip3}/><img className='badge' src={badge}/></div>
                    <div className="ad-item"><img className='pic' src={vip4}/><img className='badge' src={badge}/></div>
                    <div className="ad-item"><img className='pic' src={vip5}/><img className='badge' src={badge}/></div>
                </div>
                :
                listStyle == 'irregular'
                    ?
                    <div className="irregular">
                        <div className="ad-item box1" onClick={openAnnouncementDialog}>ilan ver 1</div>
                        <div className="ad-item box2" onClick={openAnnouncementDialog}>ilan ver 2</div>
                        <div className="ad-item box3" onClick={openVisitorDialog}><img src={ir_ilan2}/></div>
                        <div className="ad-item box4" onClick={openAnnouncementDialog}>ilan ver 4</div>
                        <div className="ad-item box5" onClick={openAnnouncementDialog}>ilan ver 5</div>
                        <div className="ad-item box6" onClick={openAnnouncementDialog}>ilan ver 6</div>
                        <div className="ad-item box7" onClick={openAnnouncementDialog}>ilan ver 7</div>
                        <div className="ad-item box8" onClick={openVisitorDialog}><img src={ir_ilan1}/></div>
                        <div className="ad-item box9" onClick={openAnnouncementDialog}>ilan ver 9</div>
                        <div className="ad-item box10" onClick={openAnnouncementDialog}>ilan ver 10</div>
                        <div className="ad-item box11" onClick={openAnnouncementDialog}>ilan ver 11</div>
                        <div className="ad-item box12" onClick={openAnnouncementDialog}>ilan ver 12</div>
                        <div className="ad-item box13" onClick={openAnnouncementDialog}>ilan ver 13</div>
                        <div className="ad-item box14" onClick={openAnnouncementDialog}>ilan ver 14</div>
                        <div className="ad-item box15" onClick={openAnnouncementDialog}>ilan ver 15</div>
                        <div className="ad-item box16" onClick={openAnnouncementDialog}>ilan ver 16</div>
                        <div className="ad-item box1" onClick={openAnnouncementDialog}>ilan ver 17</div>
                        <div className="ad-item box2" onClick={openAnnouncementDialog}>ilan ver 18</div>
                        <div className="ad-item box3" onClick={openAnnouncementDialog}>ilan ver 19</div>
                        <div className="ad-item box4" onClick={openAnnouncementDialog}>ilan ver 20</div>
                        <div className="ad-item box5" onClick={openAnnouncementDialog}>ilan ver 21</div>
                        <div className="ad-item box6" onClick={openAnnouncementDialog}>ilan ver 22</div>
                        <div className="ad-item box7" onClick={openAnnouncementDialog}>ilan ver 23</div>
                        <div className="ad-item box8" onClick={openAnnouncementDialog}>ilan ver 24</div>
                        <div className="ad-item box9" onClick={openAnnouncementDialog}>ilan ver 25</div>
                        <div className="ad-item box10" onClick={openAnnouncementDialog}>ilan ver 26</div>
                        <div className="ad-item box11" onClick={openAnnouncementDialog}>ilan ver 27</div>
                        <div className="ad-item box12" onClick={openAnnouncementDialog}>ilan ver 28</div>
                        <div className="ad-item box13" onClick={openAnnouncementDialog}>ilan ver 29</div>
                        <div className="ad-item box14" onClick={openAnnouncementDialog}>ilan ver 30</div>
                        <div className="ad-item box15" onClick={openAnnouncementDialog}>ilan ver 31</div>
                        <div className="ad-item box16" onClick={openAnnouncementDialog}>ilan ver 32</div>
                        <div className="ad-item box1" onClick={openAnnouncementDialog}>ilan ver 33</div>
                        <div className="ad-item box2" onClick={openAnnouncementDialog}>ilan ver 34</div>
                        <div className="ad-item box3" onClick={openAnnouncementDialog}>ilan ver 35</div>
                        <div className="ad-item box4" onClick={openAnnouncementDialog}>ilan ver 36</div>
                        <div className="ad-item box5" onClick={openAnnouncementDialog}>ilan ver 37</div>
                        <div className="ad-item box6" onClick={openAnnouncementDialog}>ilan ver 38</div>
                        <div className="ad-item box7" onClick={openAnnouncementDialog}>ilan ver 39</div>
                        <div className="ad-item box8" onClick={openAnnouncementDialog}>ilan ver 40</div>
                        <div className="ad-item box9" onClick={openAnnouncementDialog}>ilan ver 41</div>
                        <div className="ad-item box10" onClick={openAnnouncementDialog}>ilan ver 42</div>
                        <div className="ad-item box11" onClick={openAnnouncementDialog}>ilan ver 43</div>
                        <div className="ad-item box12" onClick={openAnnouncementDialog}>ilan ver 44</div>
                        <div className="ad-item box13" onClick={openAnnouncementDialog}>ilan ver 45</div>
                        <div className="ad-item box14" onClick={openAnnouncementDialog}>ilan ver 46</div>
                        <div className="ad-item box15" onClick={openAnnouncementDialog}>ilan ver 47</div>
                        <div className="ad-item box16" onClick={openAnnouncementDialog}>ilan ver 48</div>
                    </div>
                    :
                    <div className="regular">
                        {banners.map(item => <div className="ad-item" onClick={openVisitorDialog}><a href={`/profile/${item.id}`}><img src={item.url}/></a></div>)}
                        <div className="ad-item" onClick={openVisitorDialog}><img src={ilan}/></div>
                        <div className="ad-item" onClick={openVisitorDialog}><img src={ilan}/></div>
                        <div className="ad-item" onClick={openVisitorDialog}><img src={ilan}/></div>
                        <div className="ad-item" onClick={openVisitorDialog}><img src={ilan}/></div>
                        <div className="ad-item" onClick={openVisitorDialog}><img src={ilan}/></div>
                        <div className="ad-item" onClick={openVisitorDialog}><img src={ilan}/></div>
                        <div className="ad-item" onClick={openVisitorDialog}><img src={ilan}/></div>
                        <div className="ad-item" onClick={openVisitorDialog}><img src={ilan}/></div>
                        {re_items.map(item => <div className="ad-item" onClick={openAnnouncementDialog}>ilan ver</div>)}
                    </div>
            }


        </div>
    )
}

export default PublicItems;