import React from 'react'
import '../assets/filter.css'
import { Button } from '@mui/material';

const Filter = ({onChange}) => {
    const [selectedPlaces, setSelectedPlaces] = React.useState([]);
    const places = ["Her Place", "Hotel", "Residence"];
    const handlePlaceCheck = (event) =>{
        var updateList = [...selectedPlaces]
        event.target.checked
        ? updateList = [...selectedPlaces, event.target.value]
        : updateList.splice(selectedPlaces.indexOf(event.target.value), 1)
        setSelectedPlaces(updateList)
    }

    const [selectedServices, setSelectedServices] = React.useState([]);
    const services = ["Oral", "Anal", "Group", "Night", "Facial"];
    const handleServiceCheck = (event) =>{
        var updateList = [...selectedServices]
        event.target.checked
        ? updateList = [...selectedServices, event.target.value]
        : updateList.splice(selectedServices.indexOf(event.target.value), 1)
        setSelectedServices(updateList)
    }

    const [selectedSexuality, setSelectedSexuality] = React.useState([]);
    const sexualities = ["Lesbian", "Transgender", "Bisexual"];
    const handleCheck = (event) =>{
        var updateList = [...selectedSexuality]
        event.target.checked
        ? updateList = [...selectedSexuality, event.target.value]
        : updateList.splice(selectedSexuality.indexOf(event.target.value), 1)
        setSelectedSexuality(updateList)
    }

    const setFilter = () => {
        // var filter = [];
        var placeFilters = selectedPlaces.map(item        =>({"filter":"place" , "val":item}));
        var serviceFilters = selectedServices.map(item    =>({"filter":"service" , "val":item}));
        var sexualityFilters = selectedSexuality.map(item =>({"filter":"sexuality" , "val":item}));
        // filter = [...filter, ...playFilter];
        onChange([...placeFilters, ...serviceFilters, ...sexualityFilters])
    }

    return(
        <div className="filter-container">
            <Button color="primary"  variant="contained" size="small" onClick={setFilter}>Filter</Button>
            <div>
                <ul>
                    Place
                    {
                        places.map(item => <li><input value={item} type="checkbox" onChange={handlePlaceCheck} />{item}</li>)
                    }
                </ul>
                <ul>
                    Services
                    {
                        services.map(item => <li><input value={item} type="checkbox" onChange={handleServiceCheck} />{item}</li>)
                    }
                </ul>
                <ul>
                    Sexuality
                    {
                        sexualities.map(item => <li><input value={item} type="checkbox" onChange={handleCheck} />{item}</li>)
                    }
                </ul>
            </div>
        </div> 
    )
}

export default Filter;