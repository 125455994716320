import React from "react";
import '../assets/css/header.scss'
import { useNavigate } from "react-router-dom";


const Header = ({back}) => {
    const navigate = useNavigate();
    return(
        <header>
            <h1 className="font-effect-neon">Keyif</h1>
            {back? <div className="back-btn" onClick={e=>{navigate('/w')}}>Geri<br/> &#8592;</div> : false}
        </header>
    )
}

export default Header;